import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  InstantSearch,
  connectInfiniteHits,
  Configure,
} from "react-instantsearch-dom"
import classnames from "classnames"

import createInfiniteHitsSessionStorageCache from "@lib/createInfiniteHitsSessionStorageCache"
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import User from "@components/User"
import Search from "@components/Search"
import NoResults from "@components/NoResults"
import { useAlgolia, useIntersect, useHistory, useTranslation } from "@hooks"
import * as C from "@constants"

import Section from "react-bulma-components/lib/components/section"
import Container from "react-bulma-components/lib/components/container"
import Columns from "react-bulma-components/lib/components/columns"
import Loader from "react-bulma-components/lib/components/loader"

import styles from "./styles.module.scss"

const Hits = ({ hits, hasMore, refineNext }) => {
  const [setElement, loading] = useIntersect({ load: refineNext })

  return (
    <Section className={classnames(styles.Studios, "is-flex")}>
      <Container>
        <Columns breakpoint="mobile">
          {hits
            .filter(
              hit =>
                typeof hit._geoloc === "object" &&
                typeof hit._geoloc.lat === "number" &&
                typeof hit._geoloc.lng === "number" &&
                hit.username
            )
            .map(hit => {
              hit._geoloc = {
                lat: parseFloat(hit._geoloc.lat),
                lng: parseFloat(hit._geoloc.lng),
              }
              return hit
            })
            .map(hit => (
              <Columns.Column
                key={hit.objectID}
                mobile={{
                  size: 12,
                }}
                tablet={{
                  size: 6,
                }}
                desktop={{
                  size: 4,
                }}
                widescreen={{
                  size: 4,
                }}
                fullhd={{
                  size: 4,
                }}
                // onMouseEnter={() => setHighlightedMarker(hit.objectID)}
                // onMouseLeave={() => setHighlightedMarker(null)}
              >
                <User user={hit} />
              </Columns.Column>
            ))}
          <Columns.Column
            key={"loader"}
            mobile={{
              size: 12,
            }}
            tablet={{
              size: 12,
            }}
            desktop={{
              size: 12,
            }}
            widescreen={{
              size: 12,
            }}
            fullhd={{
              size: 12,
            }}
          >
            {/* Not currently centered */}
            {!loading && hasMore ? (
              <div ref={setElement} className={"is-vcentered is-fullwidth"}>
                <Loader className={"is-inline-block"} />
              </div>
            ) : (
              <NoResults />
            )}
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

const CustomHits = connectInfiniteHits(Hits)

const Studios = ({ location }) => {
  const [t] = useTranslation()
  const searchClient = useAlgolia()
  const [onSearchStateChange, searchState, createURL] = useHistory(location)
  const [showMap, setShowMap] = useState(false)

  return (
    <Layout>
      <SEO
        title={t("pages.Studios.seo.title")}
        description={t("pages.Studios.seo.description")}
      />
      <InstantSearch
        indexName={C.INDEX_USERS}
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <Configure
          filters={`role:${C.USERS_ROLE_STUDIO}`}
          aroundLatLngViaIP={true}
          distinct
        />
        <Search hasMap showMap={showMap} setShowMap={setShowMap} />
        {!showMap && (
          <CustomHits
            cache={createInfiniteHitsSessionStorageCache("ais.studios")}
          />
        )}
      </InstantSearch>
    </Layout>
  )
}

Studios.propTypes = {
  location: PropTypes.object,
}

export default Studios
