import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import classnames from "classnames"

import * as C from "@constants"
import { useTranslation } from "@hooks"
import CreatedAt from "@components/CreatedAt"
const styles = {}
// import Placeholder from "@components/Placeholder"

import Media from "react-bulma-components/lib/components/media"
import Image from "react-bulma-components/lib/components/image"
import Button from "react-bulma-components/lib/components/button"

const ActivityItem = ({ activity, user }) => {
  if (!activity) return null

  const [t] = useTranslation()

  // const read = useSelector((state) => state.ui.unread.activities);

  const _getBookingType = type => {
    if (type === C.BOOKING_GUEST_INVITE)
      return t("constants.bookingTypes.guestInvite")
    if (type === C.BOOKING_GUEST_REQUEST)
      return t("constants.bookingTypes.guestRequest")
    if (type === C.BOOKING_RESIDENT_INVITE)
      return t("constants.bookingTypes.residentInvite")
    if (type === C.BOOKING_RESIDENT_REQUEST)
      return t("constants.bookingTypes.residentRequest")
    if (type === C.BOOKING_TATTOO) return t("constants.bookingTypes.tattoo")
    if (type === C.BOOKING_TRAVEL) return t("constants.bookingTypes.travel")
    if (!type) return t("constants.bookingTypes.tattoo")
  }

  const _generateTitle = () => {
    switch (activity.verb) {
      case C.FOLLOW_RECEIVED:
        if (activity.actions.length > 1) {
          return (
            <div style={styles.title}>
              <span style={styles.link}>{activity.actions.length} </span>
              {t("components.ActivityItem.followReceived.")}
            </div>
          )
        } else if (get(activity, "actions[0].meta.user.username")) {
          return (
            <div style={styles.title}>
              <span style={styles.link}>
                {get(activity, "actions[0].meta.user.username")}{" "}
              </span>
              {t("components.ActivityItem.followReceived.single")}
            </div>
          )
        }
        break
      case C.LIKE_RECEIVED:
        if (activity.actions.length > 1) {
          return (
            <div style={styles.title}>
              <span
                onPress={() => {
                  // navigate(R.LIKES, {
                  //   photoId: activity.object,
                  // })
                }}
                style={styles.link}
              >
                {activity.actions.length}{" "}
              </span>
              {t("components.ActivityItem.likeReceived.multiple")}
            </div>
          )
        } else if (
          get(activity, "actions[0].meta.user.username") &&
          get(activity, "actions[0].meta.user.id")
        ) {
          return (
            <div style={styles.title}>
              <span
                onPress={() => {
                  // navigate(R.USER, {
                  //   id: get(activity, "actions[0].meta.user.id"),
                  //   user: get(activity, "actions[0].meta.user"),
                  // })
                }}
                style={styles.link}
              >
                {get(activity, "actions[0].meta.user.username")}{" "}
              </span>
              {t("components.ActivityItem.likeReceived.single")}
            </div>
          )
        }
        break
      case C.COMMENT_RECEIVED:
        if (activity.actions.length > 1) {
          return (
            <div style={styles.title}>
              <span
                onPress={() => {
                  // navigate(R.COMMENTS, {
                  //   photoId: activity.object,
                  // })
                }}
                style={styles.link}
              >
                {activity.actions.length}{" "}
              </span>
              {t("components.ActivityItem.commentReceived.multiple")}
            </div>
          )
        } else if (
          get(activity, "actions[0].meta.user.username") &&
          get(activity, "actions[0].meta.user.id")
        ) {
          return (
            <div style={styles.title}>
              <span
                onPress={() => {
                  // navigate(R.USER, {
                  //   id: get(activity, "actions[0].meta.user.id"),
                  //   user: get(activity, "actions[0].meta.user"),
                  // })
                }}
                style={styles.link}
              >
                {get(activity, "actions[0].meta.user.username")}{" "}
              </span>
              {t("components.ActivityItem.commentReceived.single")}
            </div>
          )
        }
        break
      case C.BOOKING_RECEIVED:
        if (
          get(activity, "actions[0].meta.user.username") &&
          get(activity, "actions[0].meta.user.id")
        ) {
          return (
            <div style={styles.title}>
              <span
                onPress={() => {
                  // navigate(R.USER, {
                  //   id: get(activity, "actions[0].meta.user.id"),
                  //   user: get(activity, "actions[0].meta.user"),
                  // })
                }}
                style={styles.link}
              >
                {get(activity, "actions[0].meta.user.username")}{" "}
              </span>
              {t("components.ActivityItem.bookingReceived.single", {
                booking: _getBookingType(
                  get(activity, "actions[0].meta.booking.type")
                ),
              })}
            </div>
          )
        }
        break
      case C.BOOKING_UPDATED:
        if (activity.actions.length > 1) {
          return (
            <div style={styles.title}>
              <span style={styles.link}>{activity.actions.length} </span>
              {t("components.ActivityItem.bookingUpdated.multiple", {
                booking: _getBookingType(
                  get(activity, "actions[0].meta.booking.type")
                ),
              })}
            </div>
          )
        } else if (
          get(activity, "actions[0].meta.user.username") &&
          get(activity, "actions[0].meta.user.id") &&
          get(activity, "actions[0].meta.booking.status")
        ) {
          return (
            <div style={styles.title}>
              <span
                onPress={() => {
                  // navigate(R.USER, {
                  //   id: get(activity, "actions[0].meta.user.id"),
                  //   user: get(activity, "actions[0].meta.user"),
                  // })
                }}
                style={styles.link}
              >
                {get(activity, "actions[0].meta.user.username")}{" "}
              </span>
              {t("components.ActivityItem.bookingUpdated.single", {
                booking: _getBookingType(
                  get(activity, "actions[0].meta.booking.type")
                ),
              })}
              <span style={styles.link}>
                {" "}
                {get(activity, "actions[0].meta.booking.status", {
                  booking: _getBookingType(
                    get(activity, "actions[0].meta.booking.type")
                  ),
                })}
              </span>
              .
            </div>
          )
        } else if (
          get(activity, "actions[0].meta.user.username") &&
          get(activity, "actions[0].meta.user.id") &&
          get(activity, "actions[0].meta.booking.paymentStatus")
        ) {
          return get(activity, "actions[0].meta.booking.paymentStatus") ===
            C.BOOKING_PAYMENT_STATUS_INCOMPLETE ? (
            <div style={styles.title}>
              {t(
                "components.ActivityItem.bookingUpdated.paymentStatus.incomplete",
                {
                  booking: _getBookingType(
                    get(activity, "actions[0].meta.booking.type")
                  ),
                }
              )}
              <span
                onPress={() => {
                  // navigate(R.USER, {
                  //   id: get(activity, "actions[0].meta.user.id"),
                  //   user: get(activity, "actions[0].meta.user"),
                  // })
                }}
                style={styles.link}
              >
                {" "}
                {get(activity, "actions[0].meta.user.username")}
              </span>
              {"."}
            </div>
          ) : (
            <div style={styles.title}>
              <span
                onPress={() => {
                  // navigate(R.USER, {
                  //   id: get(activity, "actions[0].meta.user.id"),
                  //   user: get(activity, "actions[0].meta.user"),
                  // })
                }}
                style={styles.link}
              >
                {get(activity, "actions[0].meta.user.username")}{" "}
              </span>
              {t(
                "components.ActivityItem.bookingUpdated.paymentStatus.updated",
                {
                  booking: _getBookingType(
                    get(activity, "actions[0].meta.booking.type")
                  ),
                }
              )}
              <span style={styles.link}>
                {" "}
                {get(activity, "actions[0].meta.booking.paymentStatus")}
              </span>
              .
            </div>
          )
        }
        break
      case C.TEST_RECEIVED:
        if (
          activity.actions.length > 1 &&
          get(activity, "actions[0].meta.user.id")
        ) {
          return (
            <div style={styles.title}>
              <span
                onPress={() => {
                  // navigate(R.USER, {
                  //   id: get(activity, "actions[0].meta.user.id"),
                  //   user: get(activity, "actions[0].meta.user"),
                  // })
                }}
                style={styles.link}
              >
                {activity.actions.length}{" "}
              </span>
              {t("components.ActivityItem.testReceived.multiple")}
            </div>
          )
        } else if (
          get(activity, "actions[0].meta.user.id") &&
          get(activity, "actions[0].meta.user.username")
        ) {
          return (
            <div style={styles.title}>
              <span
                onPress={() => {
                  // navigate(R.USER, {
                  //   id: get(activity, "actions[0].meta.user.id"),
                  //   user: get(activity, "actions[0].meta.user"),
                  // })
                }}
                style={styles.link}
              >
                {get(activity, "actions[0].meta.user.username")}{" "}
              </span>
              {t("components.ActivityItem.testReceived.single")}
            </div>
          )
        }
        break
    }
  }

  const _getAction = () => {
    switch (activity.verb) {
      case C.FOLLOW_RECEIVED:
        return () => {}
      // navigate(R.FOLLOWERS, {
      //   id: activity.object,
      // })
      case C.LIKE_RECEIVED:
        return () => {}
      // navigate(R.TATTOO, {
      //   id: activity.object,
      // })
      case C.COMMENT_RECEIVED:
        return () => {}
      // navigate(R.COMMENTS, {
      //   photoId: activity.object,
      // })
      case C.BOOKING_RECEIVED:
        return () => {}
      // return () => navigate(R.BOOKING, { id: activity.object })
      case C.BOOKING_UPDATED:
        return () => {}
      // return () => navigate(R.BOOKING, { id: activity.object })
      case C.TEST_RECEIVED:
        return () => {}
      // return () => navigate(R.PROFILE_KITCHEN_SINK)
    }
  }

  return (
    // <div
    //   style={[
    //     // styles.container,
    //     // !read && activity.unread
    //     //   ? { backgroundColor: C.BACKGROUND_COLOR_SECONDARY }
    //     //   : { backgroundColor: C.BACKGROUND_COLOR },
    //   ]}
    // >
    // <div>{JSON.stringify(activity)}</div>
    // <Link to={""} className={"has-text-black"} onClick={_getAction()}>
    <Button renderAs={"a"} className={"has-text-black"} onClick={_getAction()}>
      <Media>
        {activity.actions && activity.actions.length > 0 && (
          <Media.Item renderAs="figure" position="left">
            <Image
              size={64}
              alt={""}
              src={
                get(activity, "actions[0].meta.booking.paymentStatus") ===
                C.BOOKING_PAYMENT_STATUS_INCOMPLETE
                  ? "" // require("assets/images/placeholder.png").uri
                  : get(activity, "actions[0].meta.user.photoUrl")
              }
              rounded
              className={classnames("is-square", "user-image")}
              loading={"lazy"}
            />
          </Media.Item>
        )}
        <Media.Item>
          {_generateTitle()}
          <CreatedAt createdAt={activity.createdAt} />
        </Media.Item>
        {(activity.verb === C.LIKE_RECEIVED ||
          activity.verb === C.COMMENT_RECEIVED) && (
          <Media.Item renderAs="figure" position="left">
            <Image
              size={64}
              alt={""}
              src={
                get(activity, "actions[0].meta.photo.photoUrl")
                  ? { uri: get(activity, "actions[0].meta.photo.photoUrl") }
                  : "" // require("assets/images/placeholder.png")
              }
              rounded
              className={classnames("is-square", "user-image")}
              loading={"lazy"}
            />
          </Media.Item>
        )}
      </Media>
    </Button>
    // </Link>
    // </div>
  )
}

ActivityItem.propTypes = {
  activity: PropTypes.object,
}

export default ActivityItem
