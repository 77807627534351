import React, { useState } from "react"

import { useFirebase, useTranslation } from "@hooks"

import {
  Field,
  Control,
  // Label,
  Input,
} from "react-bulma-components/lib/components/form"
import Button from "react-bulma-components/lib/components/button"

import styles from "./styles.module.scss"

const INITIAL_STATE = {
  email: "",
  error: null,
}

const PasswordForgetForm = () => {
  const firebase = useFirebase()
  const [state, setState] = useState({ ...INITIAL_STATE })
  const [t] = useTranslation()

  const onSubmit = event => {
    const { email } = state

    firebase
      .doPasswordReset(email)
      .then(() => {
        setState({ ...INITIAL_STATE })
      })
      .catch(error => {
        setState({
          ...state,
          error,
        })
      })

    event.preventDefault()
  }

  const onChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    })
  }

  const { email, error } = state

  const isInvalid = email === ""

  return (
    <form onSubmit={onSubmit}>
      <Field>
        {/* <Label>Email</Label> */}
        <Control>
          <Input
            onChange={onChange}
            name="email"
            type="email"
            placeholder="Email"
            value={email}
          />
        </Control>
      </Field>
      <Field>
        <Control className={"has-text-centered"}>
          <Button disabled={isInvalid} color="primary" rounded>
            {t("components.PasswordForgetForm.button.text")}
          </Button>
        </Control>
      </Field>
      {error && <p>{error.message}</p>}
    </form>
  )
}

const PasswordForgetLink = ({ onPress }) => {
  const [t] = useTranslation()
  return (
    <a href="/#" onClick={onPress} className={styles.PasswordForgetLink}>
      {t("components.PasswordForgetLink.text")}
    </a>
  )
}

export default PasswordForgetForm

export { PasswordForgetLink }
