import {
  BOOKING_TATTOO,
  BOOKING_RESIDENT_INVITE,
  BOOKING_GUEST_INVITE,
  BOOKING_RESIDENT_REQUEST,
  BOOKING_GUEST_REQUEST,
  BOOKING_TRAVEL,
} from "../constants"

export const userToDisplay = (booking = {}, userId) => {
  // I am an artist looking at my Inbox
  if (booking.artist && booking.artist.id === userId) {
    if (booking.type === BOOKING_TATTOO) {
      return booking.customer
    } else if (
      booking.type === BOOKING_RESIDENT_INVITE ||
      booking.type === BOOKING_GUEST_INVITE ||
      booking.type === BOOKING_RESIDENT_REQUEST ||
      booking.type === BOOKING_GUEST_REQUEST
    ) {
      return booking.studio
    } else if (booking.type === BOOKING_TRAVEL) {
      // Travel Post should not be displayed in the...
      return booking.artist
    }
    // I am a customer looking at my Inbox
  } else if (booking.customer && booking.customer.id === userId) {
    if (booking.type === BOOKING_TATTOO) {
      return booking.artist
    } else if (
      booking.type === BOOKING_RESIDENT_INVITE ||
      booking.type === BOOKING_GUEST_INVITE ||
      booking.type === BOOKING_RESIDENT_REQUEST ||
      booking.type === BOOKING_GUEST_REQUEST
    ) {
      return booking.artist
    } else if (booking.type === BOOKING_TRAVEL) {
      // Travel Post should not be displayed here...
      return booking.artist
    }
  }
}
