import React from "react"
import classnames from "classnames"

import CreatedAt from "../CreatedAt"
import Media from "react-bulma-components/lib/components/media"
import Image from "react-bulma-components/lib/components/image"
import Heading from "react-bulma-components/lib/components/heading"

const Message = ({ message, user }) => {
  // const isSender = user?.id === message.sender?.id
  return (
    <Media>
      <Media.Item renderAs="figure" position="left">
        <Image
          size={64}
          alt={`Image of ${message.sender?.username}`}
          src={message.sender?.photoUrl}
          rounded
          className={classnames("is-square", "user-image")}
          loading={"lazy"}
        />
      </Media.Item>
      <Media.Item>
        <Heading size={4}>{message.sender?.username}</Heading>
        <Heading subtitle size={6}>
          {message.text}
        </Heading>
        <p></p>
        <CreatedAt createdAt={message.updatedAt} />
      </Media.Item>
    </Media>
  )
}

export default Message
