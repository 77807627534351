import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import ArticlePreview from "./ArticlePreview"

import Columns from "react-bulma-components/lib/components/columns"
import Container from "react-bulma-components/lib/components/container"
import Heading from "react-bulma-components/lib/components/heading"
import Section from "react-bulma-components/lib/components/section"

import styles from "./styles.module.scss"

const ArticlesList = ({ title, articles, renderAs }) => {
  if (!articles) return null
  if (!articles.length) return null

  return (
    <Section className={classnames(styles.ArticleList)}>
      <Container>
        {title && (
          <Heading
            size={3}
            renderAs={renderAs}
            className={classnames(
              styles.ArticleList__title,
              "has-text-centered has-text-black"
            )}
          >
            {title}
          </Heading>
        )}
        <Columns breakpoint="mobile">
          {articles.map(({ node }, i) => (
            <ArticlePreview key={i} article={node} />
          ))}
        </Columns>
      </Container>
    </Section>
  )
}

ArticlesList.defaultProps = {
  renderAs: "h3",
}

ArticlesList.propTypes = {
  title: PropTypes.string,
  articles: PropTypes.array,
  renderAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h5", "p"]),
}

export default ArticlesList
