import React from "react"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import { useTranslation } from "@hooks"

import Container from "react-bulma-components/lib/components/container"
import Heading from "react-bulma-components/lib/components/heading"
import Content from "react-bulma-components/lib/components/content"
import Section from "react-bulma-components/lib/components/section"

const NotFound = () => {
  const [t] = useTranslation()

  return (
    <Layout>
      <SEO
        title={t("pages.NotFound.seo.title")}
        description={t("pages.NotFound.seo.description")}
      />
      <Container className={"has-text-centered"}>
        <Section>
          <Content>
            <Heading renderAs={"h1"} size={3}>
              {t("pages.NotFound.heading")}
            </Heading>
            <p>{t("pages.NotFound.text")}</p>
          </Content>
        </Section>
      </Container>
    </Layout>
  )
}

export default NotFound
