import React, { useState } from "react"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import User from "@components/User"
import Chat from "@components/Chat"
import { useBooking, useTranslation } from "@hooks"
import { userToDisplay } from "@services"

import Container from "react-bulma-components/lib/components/container"
import Heading from "react-bulma-components/lib/components/heading"
import Content from "react-bulma-components/lib/components/content"
import Section from "react-bulma-components/lib/components/section"
import Tabs from "react-bulma-components/lib/components/tabs"
// import Tag from "react-bulma-components/lib/components/tag"

const BookingDetails = ({ user, booking }) => {
  const sender = userToDisplay(booking, user?.id)
  const [tab, setTab] = useState(0)

  return (
    <>
      <Tabs type={"toggle-rounded"} fullwidth={true}>
        <Tabs.Tab active={tab === 0} onClick={() => setTab(0)}>
          Details
        </Tabs.Tab>
        <Tabs.Tab active={tab === 1} onClick={() => setTab(1)}>
          Chat
        </Tabs.Tab>
      </Tabs>
      {tab === 0 && (
        <Section>
          <Content>
            <User user={sender} />
            <Heading subtitle size={5}>
              Type
            </Heading>
            <p>{booking.type}</p>
            <Heading subtitle size={5}>
              Start Date
            </Heading>
            <p>{new Date(booking.startDate).toDateString()}</p>
            <Heading subtitle size={5}>
              End Date
            </Heading>
            <p>{new Date(booking.endDate).toDateString()}</p>
            <Heading subtitle size={5}>
              Description
            </Heading>
            <p>{booking.description}</p>
          </Content>
        </Section>
      )}
      {tab === 1 && <Chat booking={booking} user={user} />}
    </>
  )
}

const Booking = ({ id, currentUser }) => {
  const [t] = useTranslation()
  const booking = useBooking(id)
  if (!booking) return null
  return (
    <Layout>
      <SEO title={t("pages.Booking.seo.title")} />
      <Container>
        <BookingDetails booking={booking} user={currentUser} />
      </Container>
    </Layout>
  )
}

export default Booking
