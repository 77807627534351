import React from "react"
import { PageProps } from "gatsby"
import classnames from "classnames"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import { useTranslation } from "@hooks"

import YourInk from "@components/Book/YourInk"
import YourSkin from "@components/Book/YourSkin"
import YourStyle from "@components/Book/YourStyle"

import Section from "react-bulma-components/lib/components/section"
import Container from "react-bulma-components/lib/components/container"
import Heading from "react-bulma-components/lib/components/heading"
import Columns from "react-bulma-components/lib/components/columns"
import Card from "react-bulma-components/lib/components/card"
import Hero from "react-bulma-components/lib/components/hero"

import styles from "./styles.module.scss"

const BookATattoo = (props: PageProps) => {
  const [t] = useTranslation()

  const why = [
    {
      title: "Be Inspired",
      image: <YourInk />,
      content: "Want a tattoo but not sure what to get? Let us help.",
    },
    {
      title: "More Choice",
      image: <YourSkin />,
      content: "We want you to be able to find the perfect tattoo.",
    },
    {
      title: "Book Securely",
      image: <YourStyle />,
      content: "Search previous work by artists and reach out.",
    },
  ]

  return (
    <Layout>
      <SEO
        title={t("pages.BookATattoo.seo.title")}
        description={t("pages.BookATattoo.seo.description")}
      />

      <Hero size="medium">
        <Hero.Body className={"has-background-black"}>
          <Container className={classnames("has-text-centered")}>
            <Heading
              className={classnames(
                styles.BookATattoo__heading,
                "has-text-white-bis"
              )}
            >
              {t("pages.BookATattoo.heading")}
            </Heading>
            <p
              className={classnames(
                styles.BookATattoo__description,
                "has-text-white-bis"
              )}
            >
              A tattoo search engine, built for you.
            </p>
          </Container>
        </Hero.Body>
      </Hero>
      <Container>
        <Section>
          <Columns breakpoint="mobile" className={"is-vcentered"}>
            {why.map((w, i) => (
              <Columns.Column
                key={i}
                mobile={{
                  size: 12,
                }}
                tablet={{
                  size: 4,
                }}
                desktop={{
                  size: 4,
                }}
                widescreen={{
                  size: 4,
                }}
                fullhd={{
                  size: 4,
                }}
                className={"has-text-centered"}
              >
                <Card className={"is-card-equal-height"}>
                  {w.image && w.image}
                  <Card.Content className={styles.BookATattoo__whycontent}>
                    <Heading renderAs={"h4"} size={5}>
                      {w.title}
                    </Heading>
                    <p>{w.content}</p>
                  </Card.Content>
                </Card>
              </Columns.Column>
            ))}
          </Columns>
        </Section>
      </Container>
    </Layout>
  )
}

export default BookATattoo
