import React from "react"
import { PageProps } from "gatsby"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import { useTranslation } from "@hooks"

import Section from "react-bulma-components/lib/components/section"
import Container from "react-bulma-components/lib/components/container"
import Content from "react-bulma-components/lib/components/content"

const Terms = (props: PageProps) => {
  const [t] = useTranslation()
  return (
    <Layout>
      <SEO title="Terms" />
      <Section>
        <Container>
          <Content>
            <h1>{t("pages.Terms.heading")}</h1>‍
            <p>
              Please read these Terms and Conditions ("Terms", "Terms and
              Conditions") carefully before using the iynk.com website and the
              “iynk” mobile application (the "Service") operated by iynk, LTD.
              ("us", "we", or "our").
            </p>
            ‍
            <p>
              Your access to and use of the Service is conditioned on your
              acceptance of and compliance with these Terms. These Terms apply
              to all visitors, users and others who access or use the Service.
              These Terms shall be governed by and interpreted according to
              English Law and the parties submit to the exclusive jurisdiction
              of the English Courts.
            </p>
            ‍
            <p>
              By accessing or using the Service you agree to be bound by these
              Terms. If you disagree with any part of the terms then you may not
              access the Service. “You” and “your” refer to you, as a user of
              the Service. A “user” is someone who accesses, browses or in any
              way uses the Service. “We,” “us,” and “our” refer to iynk.
            </p>
            ‍
            <p>
              We grant you permission to use the Service subject to the
              restrictions on these Terms. Your use of the Service is at your
              own risk, including the risk that you might be exposed to Content
              that is offensive, indecent, inaccurate, objectionable, or
              otherwise inappropriate.
            </p>
            <h3>Purchases</h3>
            <p>
              If you wish to purchase any product or service made available
              through the Service ("Purchase"), you may be asked to supply
              certain information relevant to your Purchase including, without
              limitation, your credit card number, the expiration date of your
              credit card, your billing address, and your shipping information.
              You represent and warrant that: (i) you have the legal right to
              use any credit card(s) or other payment method(s) in connection
              with any Purchase; and that (ii) the information you supply to us
              is true, correct and complete.
            </p>
            ‍
            <p>
              The service may employ the use of third party services for the
              purpose of facilitating payment and the completion of Purchases.
              By submitting your information, you grant us the right to provide
              the information to these third parties subject to our Privacy
              Policy. We reserve the right to refuse or cancel your order at any
              time for reasons including but not limited to: product or service
              availability, errors in the description or price of the product or
              service, error in your order or other reasons. We reserve the
              right to refuse or cancel your order if fraud or an unauthorized
              or illegal transaction is suspected.
            </p>
            <h3>Content</h3>
            <p>
              Our Service allows you to post, link, store, share and otherwise
              make available certain information, text, graphics, videos, or
              other material ("Content"). You are responsible for the Content
              that you post on or through the Service, including its legality,
              reliability, and appropriateness. By posting Content on or through
              the Service, You represent and warrant that: (i) the Content is
              yours (you own it) and/or you have the right to use it and the
              right to grant us the rights and license as provided in these
              Terms, and (ii) that the posting of your Content on or through the
              Service does not violate the privacy rights, publicity rights,
              copyrights, contract rights or any other rights of any person or
              entity. We reserve the right to terminate the account of anyone
              found to be infringing on a copyright. You retain any and all of
              your rights to any Content you submit, post or display on or
              through the Service and you are responsible for protecting those
              rights. We take no responsibility and assume no liability for
              Content you or any third party posts on or through the Service or
              publicly as a result of the Service. The extent to which your
              Content is available publicly is determined by settings you choose
              as part of the Service. You are responsible for the ways in which
              your Content is published through the Service. The Service assumes
              no responsibility for how the Content is shared by anyone not
              employed by the Service. iynk has the right but not the obligation
              to monitor and edit all Content provided by users. In addition,
              Content created by this Service is the property of iynk and may
              only be used as intended as part of the Service or with
              permission. You may not distribute, modify, transmit, reuse,
              download, repost, copy, or use said Content, whether in whole or
              in part, for commercial purposes or for personal gain, outside of
              Content’s intended purposes without express advance written
              permission from us.
            </p>
            <h3>Availability, Errors and Inaccuracies</h3>
            <p>
              We are constantly updating product and service offerings on the
              Service. We may experience delays in updating information on the
              Service and in our advertising on other web sites. The information
              found on the Service may contain errors or inaccuracies and may
              not be complete or current. Products or services may be mispriced,
              described inaccurately, or unavailable on the Service and we
              cannot guarantee the accuracy or completeness of any information
              found on the Service. We therefore reserve the right to change or
              update information and to correct errors, inaccuracies, or
              omissions at any time without prior notice.
            </p>
            <h3>Accounts</h3>
            <p>
              When you create an account with us, you guarantee that you are
              above the age of 15, and that the information you provide us is
              accurate, complete, and current at all times. Inaccurate,
              incomplete, or obsolete information may result in the immediate
              termination of your account on the Service. You are responsible
              for maintaining the confidentiality of your account, including but
              not limited to the restriction of access to your computer and/or
              account. You agree to accept responsibility for any and all
              activities or actions that occur under your account. You must
              notify us immediately upon becoming aware of any breach of
              security or unauthorized use of your account.
            </p>
            <h3>User Restrictions</h3>
            <p>
              Your permission to use the Service is conditioned upon the
              following Restrictions: You agree that you will not under any
              circumstances:
            </p>
            ‍
            <ul>
              <li>
                Post any information that is abusive, threatening, obscene,
                defamatory, libelous, or racially, sexually, religiously, or
                otherwise objectionable and offensive.
              </li>
              <li>
                Use the service for any unlawful purpose or for the promotion of
                illegal activities.
              </li>
              <li>
                Attempt to, or harass, abuse or harm another person or group.
              </li>
              <li>Use another user’s account without permission.</li>
              <li>
                Provide false or inaccurate information when registering an
                account.
              </li>
              <li>
                Interfere or attempt to interfere with the proper functioning of
                the Service.
              </li>
              <li>
                Make any automated use of the system, or take any action that we
                deem to impose or to potentially impose an unreasonable or
                disproportionately large load on our servers or network
                infrastructure.
              </li>
              <li>
                Bypass any robot exclusion headers or other measures we take to
                restrict access to the Service or use any software, technology,
                or device to scrape, spider, or crawl the Service or harvest or
                manipulate data.
              </li>
              <li>
                Publish or link to malicious content intended to damage or
                disrupt another user’s browser or computer.
              </li>
              <li>
                We reserve the right to refuse service, terminate accounts,
                remove or edit content, or cancel orders at our sole discretion.
              </li>
            </ul>
            <h3>Intellectual Property</h3>
            <p>
              The Service and its original content (excluding Content provided
              by users), features and functionality are and will remain the
              exclusive property of iynk and its licensors. The Service is
              protected by copyright, trademark, and other laws of both the
              United Kingdom and foreign countries. Our trademarks and trade
              dress may not be used in connection with any product or service
              without the prior written consent of iynk.
            </p>
            <h3>Carrying Out a Tattoo</h3>
            <p>
              Iynk does not accept any responsibility once a tattoo has been
              arranged. It it both the user and the artist's duty to ensure the
              tattoo is carried out safely. Iynk would recommend that as a user
              you speak to your artist about the following:
            </p>
            <p>
              If you have any condition that might affect the healing of this
              tattoo, you will advise the artist. You are not pregnant or
              nursing. You are not under the influence of alcohol or drugs.
            </p>
            <p>
              You do not have medical or skin conditions such as but not limited
              to: acne, scarring (Keloid) eczema, psoriasis, freckles, moles or
              sunburn in the area to be tattooed that may interfere with said
              tattoo. If you have any type of infection or rash anywhere on my
              body, you will advise the tattooer.
            </p>
            <p>
              You acknowledge it is not reasonably possible for the
              representatives and employees of the tattoo shop to determine
              whether you might have an allergic reaction to the pigments or
              processes used in the tattoo, and you agree to accept the risk
              that such a reaction is possible.
            </p>
            <p>
              You acknowledge that infection is always possible as a result of
              the obtaining of a tattoo, particularly in the event that you do
              not take proper care of my tattoo. You have received aftercare
              instructions and you agree to follow them while the tattoo is
              healing. You agree that any touch-up work needed, due to your own
              negligence, will be done at your own expense.
            </p>
            <p>
              You understand that if you have any skin treatments, laser hair
              removal, plastic surgery or other skin altering procedures, it may
              result in adverse changes to the tattoo.
            </p>
            <p>
              You realize that variations in color and design may exist between
              any tattoo as selected by you and as ultimately applied to your
              body. You understand that if your skin color is dark, the colors
              will not appear as bright as they do on light skin.
            </p>
            <p>
              You acknowledge that a tattoo is a permanent change to my
              appearance and that no representations have been made to you as to
              the ability to later change or remove the tattoo. To your
              knowledge, you do not have a physical, mental or medical
              impairment or disability which might affect your well being as a
              direct or indirect result of your decision to have a tattoo.
            </p>
            <p>
              You acknowledge you am over the age of eighteen and that I have
              truthfully represented to your tattooer that the obtaining of a
              tattoo is by your choice alone. You consent to the application of
              the tattoo and to any actions or conduct of the representatives
              and employees of the tattoo shop reasonably necessary to perform
              the tattoo procedure.
            </p>
            <h3>Limitation of Liability</h3>
            <p>
              UNDER NO CIRCUMSTANCES SHALL COMPANY, ITS SUBSIDIARIES,
              AFFILIATES, DIRECTORS, OFFICERS, MEMBERS, MANAGERS, EMPLOYEES OR
              AGENTS, ITS SUPPLIERS OR THEIR RESPECTIVE DIRECTORS, OFFICERS,
              EMPLOYEES OR AGENTS BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY
              INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES,
              INCLUDING, BUT NOT LIMITED TO, LOST PROFITS AND BUSINESS
              INTERRUPTION, WHETHER IN CONTRACT OR IN TORT, INCLUDING
              NEGLIGENCE, ARISING IN ANY WAY FROM ANY GOOD SOLD OR PROVIDED ON
              THE SITE, EVEN IF COMPANY IS EXPRESSLY ADVISED OF THE POSSIBILITY
              OF SUCH DAMAGES. IN NO EVENT SHALL COMPANY’S LIABILITY EXCEED THE
              PRICE YOU PAID FOR THE PRODUCT OR SERVICE THAT IS THE SUBJECT OF
              THE CLAIM.
            </p>
            <h3>Electronic Newsletters</h3>
            <p>
              If User decides to opt-in to our mailing list, they will receive
              emails that may include company news, updates, related product or
              service information, etc. We may use third party service providers
              to help us operate our business and the Site or administer
              activities on our behalf, such as sending out newsletters or
              surveys. We may share your information with these third parties
              for those limited purposes provided that you have given us your
              permission.
            </p>
            <h3>Links To Other Web Sites</h3>
            <p>
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by iynk. iynk has no control
              over, and assumes no responsibility for, the content, privacy
              policies, or practices of any third party web sites or services.
              You further acknowledge and agree that iynk shall not be
              responsible or liable, directly or indirectly, for any damage or
              loss caused or alleged to be caused by or in connection with use
              of or reliance on any such content, goods or services available on
              or through any such web sites or services.
            </p>
            <h3>Changes</h3>
            <p>
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. If a revision is material we will try to
              provide at least 30 days' notice prior to any new terms taking
              effect. What constitutes a material change will be determined at
              our sole discretion.
            </p>
            <h3>Jurisdiction and Choice of Law</h3>
            <p>
              These terms and conditions are governed by English law and the
              parties submit to the exclusive jurisdiction of the English courts
              in relation to any dispute (contractual or otherwise) concerning
              these terms and conditions.
            </p>
          </Content>
        </Container>
      </Section>
    </Layout>
  )
}

export default Terms
