import React, { useState } from "react"
import { PageProps } from "gatsby"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
// import User from "@components/User"
import { BOOKING_PLACEMENTS, BOOKING_SIZES, BOOKING_BUDGETS } from "@constants"

import {
  Field,
  Control,
  Label,
  Textarea,
} from "react-bulma-components/lib/components/form"
import Section from "react-bulma-components/lib/components/section"
import Tag from "react-bulma-components/lib/components/tag"
import Container from "react-bulma-components/lib/components/container"
import Button from "react-bulma-components/lib/components/button"
import { useTranslation } from "react-i18next"

const Book = (props: PageProps) => {
  const [t] = useTranslation()

  const onSubmit = () => {}

  const [placementOptions] = useState(
    BOOKING_PLACEMENTS.map(p => t(`pages.Book.inputs.placement.options.${p}`))
  )

  const [sizeOptions] = useState(
    BOOKING_SIZES.map(s => t(`pages.Book.inputs.size.options.${s}`))
  )

  const [budgetOptions] = useState(
    BOOKING_BUDGETS.map(b => t(`pages.Book.inputs.budget.options.${b}`))
  )

  return (
    <Layout>
      <SEO title={t("pages.Book.seo.title")} />
      <Section>
        <Container>
          {/* <User user={photo.user} shadowless marginless paddingless /> */}
          <form onSubmit={onSubmit}>
            <Field>
              <Label>{t(`pages.Book.inputs.description.label`)}</Label>
              <Control>
                <Textarea
                  placeholder={t(`pages.Book.inputs.description.placeholder`)}
                />
              </Control>
            </Field>
            <Field>
              <Label>{t(`pages.Book.inputs.placement.label`)}</Label>
              <Control>
                {/* <Button.Group>
              {types.map((type, i) => (
                <Button
                  onClick={() => select(type)}
                  key={i}
                  renderAs="span"
                  color={
                    // A bit hacky...
                    (type === "all" && !items.find(item => item.isRefined)) ||
                    items.find(
                      item => item.label === invertedTypes[type] && item.isRefined
                    )
                      ? "dark"
                      : "light"
                  }
                  rounded
                >
                  <span className={"is-capitalized"}>{type}</span>
                </Button>
              ))} */}
                <Tag.Group>
                  {placementOptions.map((placement, i) => (
                    <Tag key={i} rounded>
                      {placement}
                    </Tag>
                  ))}
                </Tag.Group>
              </Control>
            </Field>
            <Field>
              <Label>{t(`pages.Book.inputs.size.label`)}</Label>
              <Control>
                <Tag.Group>
                  {sizeOptions.map((size, i) => (
                    <Tag key={i} rounded>
                      {size}
                    </Tag>
                  ))}
                </Tag.Group>
              </Control>
            </Field>
            <Field>
              <Label>{t(`pages.Book.inputs.budget.label`)}</Label>
              <Control>
                <Tag.Group>
                  {budgetOptions.map((budget, i) => (
                    <Tag key={i} rounded>
                      {budget}
                    </Tag>
                  ))}
                </Tag.Group>
              </Control>
            </Field>
            {/* Reference Images */}
            <Field>
              <Control className={"has-text-centered"}>
                <Button
                  // disabled={isInvalid}
                  color="primary"
                  rounded
                >
                  {/* {t("components.SignUpForm.button.text")} */}
                  Book
                </Button>
              </Control>
            </Field>
            {/* {error && <p>{error.message}</p>} */}
          </form>
        </Container>
      </Section>
    </Layout>
  )
}

export default Book
