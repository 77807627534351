import React from "react"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import PasswordForgetForm from "@components/PasswordForget"
import PasswordChangeForm from "@components/PasswordChange"
// import LoginManagement from "@components/LoginManagement"
import { useTranslation } from "@hooks"

const ChangePassword = ({ currentUser }) => {
  const [t] = useTranslation()
  return (
    <Layout>
      <SEO title={t("pages.ChangePassword.seo.title")} />
      <div>
        <h1>Account: {currentUser.email}</h1>
        <PasswordForgetForm />
        <PasswordChangeForm />
        {/* <LoginManagement authUser={authUser} /> */}
      </div>
    </Layout>
  )
}

export default ChangePassword
