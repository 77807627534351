import React from "react"

import Message from "./Message"
import MessageForm from "./MessageForm"

import Section from "react-bulma-components/lib/components/section"

import { useMessages } from "@hooks"

const Chat = ({ booking, user }) => {
  if (!booking) return
  const messages = useMessages(booking)
  return (
    <Section>
      {messages.map((message, i) => (
        <Message key={i} message={message} user={user} />
      ))}
      <MessageForm booking={booking} user={user} />
    </Section>
  )
}

export default Chat
