import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Price from "@components/Price"
import { useResize } from "@hooks"

import Box from "react-bulma-components/lib/components/box"
import Image from "react-bulma-components/lib/components/image"

import styles from "./styles.module.scss"

const Photo = ({ photo, index }) => {
  const imageRef = useRef(null)
  const { width } = useResize(imageRef)

  const imageRatio = width / photo.width

  return (
    <Box paddingless className={styles.Photo} domRef={imageRef}>
      <Link to={`/tattoos/${photo.id}/`}>
        {photo.width && photo.height ? (
          <Image
            src={photo.photoUrl}
            style={{
              width: width,
              height: imageRatio * photo.height,
            }}
            loading={"lazy"}
          />
        ) : (
          <Image
            src={photo.photoUrl}
            style={{ width: width }}
            loading={"lazy"}
          />
        )}
      </Link>
      <Price
        price={photo.price}
        type={photo.type}
        className={styles.Photo__price}
      />
    </Box>
  )
}

Photo.propTypes = {
  photo: PropTypes.object,
  index: PropTypes.number,
}

export default Photo
