import React, { useState } from "react"
import { v4 as uuidv4 } from "uuid"

import {
  Field,
  Control,
  Label,
  Textarea,
} from "react-bulma-components/lib/components/form"
import Button from "react-bulma-components/lib/components/button"

import { useLazyQuery } from "@hooks"

const MessageForm = ({ booking, user }) => {
  const INITIAL_STATE = {
    _id: uuidv4(),
    text: "",
    user: {
      _id: user?.id,
      id: user?.id,
      username: user?.username,
    },
  }
  const [message, setMessage] = useState(INITIAL_STATE)
  const [image, setImage] = useState({})
  const [messagesCreate, { loading }] = useLazyQuery(
    "messagesCreate",
    {
      artist: booking?.artist,
      bookingId: booking?.id,
      customer: booking?.customer,
      message: {
        ...message,
        ...image,
      },
    },
    {
      onCompleted: () => setMessage(INITIAL_STATE),
    }
  )
  const onSubmit = async event => {
    event.preventDefault()
    if (!message) return
    messagesCreate()
  }

  const onChange = event => {
    console.log("event.target.name", event.target.name)
    console.log("event.target.value", event.target.value)
    setMessage({ ...message, [event.target.name]: event.target.value })
  }

  const { text } = message

  return (
    <form onSubmit={onSubmit}>
      <Field>
        <Label>Message</Label>
        <Control>
          <Textarea
            name={"text"}
            onChange={onChange}
            placeholder="Enter a message"
            value={text}
          />
        </Control>
      </Field>
      <Field kind="group">
        <Control>
          <Button disabled={loading || !text} type="primary">
            Submit
          </Button>
        </Control>
      </Field>
    </form>
  )
}

export default MessageForm
