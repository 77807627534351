import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classnames from "classnames"

import More from "@components/More"
import { useTranslation } from "@hooks"

import Columns from "react-bulma-components/lib/components/columns"
import Heading from "react-bulma-components/lib/components/heading"
import Card from "react-bulma-components/lib/components/card"

import styles from "./styles.module.scss"

const ArticlePreview = ({ article, isBlack }) => {
  const [t] = useTranslation()
  if (!article) return null
  return (
    <Columns.Column
      mobile={{
        size: 12,
      }}
      tablet={{
        size: 6,
      }}
      desktop={{
        size: 4,
      }}
      widescreen={{
        size: 4,
      }}
      fullhd={{
        size: 4,
      }}
    >
      <Card
        className={classnames("is-card-equal-height", styles.ArticlePreview, {
          "has-background-black": isBlack,
        })}
      >
        <Link
          to={`/articles/${article.slug}`}
          className={styles.ArticlePreview__imageContainer}
        >
          <Img
            alt={`${article.title} Hero Image`}
            fluid={article.heroImage?.fluid}
            className={styles.ArticlePreview__image}
          />
          <div className={styles.ArticlePreview__gradient} />
        </Link>
        <Card.Content className={styles.ArticlePreview__content}>
          <Heading renderAs={"h3"} size={5}>
            <Link
              to={`/articles/${article.slug}`}
              className={classnames({
                "has-text-black": !isBlack,
                "has-text-white-bis": isBlack,
              })}
            >
              {article.title}
            </Link>
          </Heading>
          {/* <small
            className={classnames(
              "is-block",
              "is-uppercase",
              styles.ArticlePreview__date,
              {
                "has-text-black": !isBlack,
                "has-text-white-bis": isBlack,
              }
            )}
          >
            {article.publishDate}
          </small> */}
          <div
            dangerouslySetInnerHTML={{
              __html: article.description?.childMarkdownRemark.html,
            }}
            className={classnames("is-block", styles.ArticlePreview__body, {
              "has-text-black": !isBlack,
              "has-text-white-bis": isBlack,
            })}
          />
        </Card.Content>
        <Card.Footer className={styles.ArticlePreview__footer}>
          <More
            to={`/articles/${article.slug}`}
            text={t("components.ArticlePreview.more")}
            linkClassName={classnames({
              "has-text-black": !isBlack,
              "has-text-white-bis": isBlack,
            })}
          />
        </Card.Footer>
      </Card>
    </Columns.Column>
  )
}

ArticlePreview.defaultProps = {
  isBlack: false,
  isFull: false,
}

ArticlePreview.propTypes = {
  isBlack: PropTypes.bool.isRequired,
  isFull: PropTypes.bool.isRequired,
}

export default ArticlePreview
