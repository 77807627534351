import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"

import CreatedAt from "@components/CreatedAt"
import { userToDisplay } from "@services"

import Media from "react-bulma-components/lib/components/media"
import Image from "react-bulma-components/lib/components/image"
import Heading from "react-bulma-components/lib/components/heading"
import Tag from "react-bulma-components/lib/components/tag"

const BookingItem = ({ booking, user }) => {
  if (!booking) return null
  const sender = userToDisplay(booking, user.id)
  return (
    <Link to={`/bookings/${booking.id}`} className={"has-text-black"}>
      <Media>
        <Media.Item renderAs="figure" position="left">
          <Image
            size={64}
            alt={`Image of ${sender?.username}`}
            src={sender?.photoUrl}
            rounded
            className={classnames("is-square", "user-image")}
            loading={"lazy"}
          />
        </Media.Item>
        <Media.Item>
          <Heading size={4}>{sender?.username}</Heading>
          <p>
            {booking.latestMessage
              ? booking.latestMessage.text
              : booking.description}
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p>{booking.type} &middot;</p>
            <CreatedAt createdAt={booking.updatedAt} />
            <Tag color="primary">
              {booking.paymentStatus ? booking.paymentStatus : booking.status}
            </Tag>
          </div>
        </Media.Item>
      </Media>
    </Link>
  )
}

export default BookingItem
