import React from "react"
import { PageProps } from "gatsby"
import classnames from "classnames"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import { useTranslation } from "@hooks"

import TomAbbott from "@components/Team/TomAbbott"
import AlexChin from "@components/Team/AlexChin"
import RosalieHurr from "@components/Team/RosalieHurr"
import AliceSnape from "@components/Team/AliceSnape"

import Section from "react-bulma-components/lib/components/section"
import Container from "react-bulma-components/lib/components/container"
import Heading from "react-bulma-components/lib/components/heading"
import Columns from "react-bulma-components/lib/components/columns"
import Card from "react-bulma-components/lib/components/card"
import Content from "react-bulma-components/lib/components/content"
import Hero from "react-bulma-components/lib/components/hero"

import styles from "./styles.module.scss"

const Team = (props: PageProps) => {
  const [t] = useTranslation()

  const why = [
    {
      title: "Tom Abbott",
      image: <TomAbbott />,
      content: "",
    },
    {
      title: "Alex Chin",
      image: <AlexChin />,
      content: "",
    },
    {
      title: "Alice Snape",
      image: <AliceSnape />,
      content: "",
    },
    {
      title: "Rosalie Hurr",
      image: <RosalieHurr />,
      content: "",
    },
  ]

  return (
    <Layout>
      <SEO
        title={t("pages.Team.seo.title")}
        description={t("pages.Team.seo.description")}
      />

      <Hero size="medium">
        <Hero.Body className={"has-background-black"}>
          <Container className={classnames("has-text-centered")}>
            <Heading
              className={classnames(styles.Team__heading, "has-text-white-bis")}
            >
              {t("pages.Team.heading")}
            </Heading>
            <p
              className={classnames(
                styles.Team__description,
                "has-text-white-bis"
              )}
            >
              Do you want to help build this marketplace?
            </p>
          </Container>
        </Hero.Body>
      </Hero>
      <Container>
        <Section>
          <Heading renderAs={"h3"} size={4}>
            The Team
          </Heading>
          <Columns breakpoint="mobile" className={"is-vcentered"}>
            {why.map((w, i) => (
              <Columns.Column
                key={i}
                mobile={{
                  size: 6,
                }}
                tablet={{
                  size: 3,
                }}
                desktop={{
                  size: 3,
                }}
                widescreen={{
                  size: 3,
                }}
                fullhd={{
                  size: 3,
                }}
                className={"has-text-centered-mobile"}
              >
                <Card className={"is-card-equal-height"}>
                  {w.image && w.image}
                  <Card.Content className={styles.Team__whycontent}>
                    <Heading renderAs={"h4"} size={5}>
                      {w.title}
                    </Heading>
                  </Card.Content>
                </Card>
              </Columns.Column>
            ))}
          </Columns>
        </Section>
        <Section>
          <Content>
            <Heading renderAs={"h3"} size={4}>
              Our Story
            </Heading>
            <p>
              Founded in late 2017, IYNK was conceived by our CEO, Tom Abbott. A
              fan of tattoos, Tom was then looking for an artist to work on his
              next piece. At that time, if you were looking for tattoo
              inspiration, you could browse Pinterest, search Instagram or talk
              to friends who might recommend artists but there was not a
              reputable website on which you could book a tattoo.
            </p>
            <p>
              Tom set out to gather as much research as he could to make his new
              website idea a reality. After attending several tattoo conventions
              and interviewing thousands of artists (often getting tattoos in
              the process), Tom started to work on the designs for the MVP.
            </p>
            <p>
              Later, about a year of research, Tom partnered with CTO Alex Chin,
              who had previously worked as a software development instructor and
              tech entrepreneur in his own right. They decided to work together
              to build a platform similar to OpenTable and TripAdvisor but for
              booking tattoos.
            </p>
          </Content>
        </Section>
        <Section>
          <Content>
            <Heading renderAs={"h3"} size={4}>
              Join the Team
            </Heading>
            <p>
              We're currently looking for anyone who is keen to help grow IYNK.
              If you are an investor, advisor or just keen to add something to
              our team, please{" "}
              <a href={"mailto:hello@iynk.com"} target={"_blank"}>
                email us
              </a>
              .
            </p>
          </Content>
        </Section>
      </Container>
    </Layout>
  )
}

export default Team
