import React, { useState } from "react"

import { useFirebase, useTranslation } from "@hooks"

import {
  Field,
  Control,
  // Label,
  Input,
} from "react-bulma-components/lib/components/form"
import Button from "react-bulma-components/lib/components/button"

const INITIAL_STATE = {
  password: "",
  passwordConfirmation: "",
  error: null,
}

const PasswordChangeForm = () => {
  const firebase = useFirebase()
  const [state, setState] = useState({ ...INITIAL_STATE })
  const [t] = useTranslation()

  const onSubmit = event => {
    const { password } = state

    firebase
      .doPasswordUpdate(password)
      .then(() => {
        setState({ ...INITIAL_STATE })
      })
      .catch(error => {
        setState({ error })
      })

    event.preventDefault()
  }

  const onChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    })
  }

  const { password, passwordConfirmation, error } = state

  const isInvalid = password !== passwordConfirmation || password === ""

  return (
    <form onSubmit={onSubmit}>
      <Field>
        {/* <Label>Password</Label> */}
        <Control>
          <Input
            onChange={onChange}
            name="password"
            type="password"
            placeholder="password"
            value={password}
          />
        </Control>
      </Field>
      <Field>
        {/* <Label>Password Confirmation</Label> */}
        <Control>
          <Input
            onChange={onChange}
            name="passwordConfirmation"
            type="passwordConfirmation"
            placeholder="passwordConfirmation"
            value={passwordConfirmation}
          />
        </Control>
      </Field>
      <Field>
        <Control className={"has-text-centered"}>
          <Button disabled={isInvalid} color="primary" rounded>
            {t("components.PasswordChangeForm.button.text")}
          </Button>
        </Control>
      </Field>
      {error && <p>{error.message}</p>}
    </form>
  )
}

export default PasswordChangeForm
