import React from "react"
import PropTypes from "prop-types"
import {
  InstantSearch,
  connectInfiniteHits,
  Configure,
} from "react-instantsearch-dom"

import createInfiniteHitsSessionStorageCache from "@lib/createInfiniteHitsSessionStorageCache"
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import User from "@components/User"
import Search from "@components/Search"
import NoResults from "@components/NoResults"
import { useAlgolia, useIntersect, useHistory, useTranslation } from "@hooks"
import * as C from "@constants"

import Container from "react-bulma-components/lib/components/container"
import Columns from "react-bulma-components/lib/components/columns"
import Section from "react-bulma-components/lib/components/section"
import Loader from "react-bulma-components/lib/components/loader"

const Hits = ({ hits, hasMore, refineNext }) => {
  const [setElement, loading] = useIntersect({ load: refineNext })
  return (
    <Section>
      <Container>
        <Columns breakpoint="mobile">
          {hits
            .filter(hit => hit.username)
            .map((hit, i) => (
              <Columns.Column
                key={i}
                mobile={{
                  size: 12,
                }}
                tablet={{
                  size: 6,
                }}
                desktop={{
                  size: 6,
                }}
                widescreen={{
                  size: 4,
                }}
                fullhd={{
                  size: 4,
                }}
              >
                <User user={hit} />
              </Columns.Column>
            ))}
          <Columns.Column
            key={"loader"}
            mobile={{
              size: 12,
            }}
            tablet={{
              size: 12,
            }}
            desktop={{
              size: 12,
            }}
            widescreen={{
              size: 12,
            }}
            fullhd={{
              size: 12,
            }}
          >
            {!loading && hasMore ? (
              <div ref={setElement} className={"is-vcentered is-fullwidth"}>
                <Loader className={"is-inline-block"} />
              </div>
            ) : (
              <NoResults />
            )}
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

const CustomHits = connectInfiniteHits(Hits)

const Artists = ({ location }) => {
  const [t] = useTranslation()
  const searchClient = useAlgolia()
  const [onSearchStateChange, searchState, createURL] = useHistory(location)
  return (
    <Layout>
      <SEO
        title={t("pages.Artists.seo.title")}
        description={t("pages.Artists.seo.description")}
      />
      <InstantSearch
        indexName={C.INDEX_USERS}
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <Search />
        <Configure filters={`role:${C.USERS_ROLE_ARTIST}`} distinct />
        <CustomHits
          cache={createInfiniteHitsSessionStorageCache("ais.artists")}
        />
      </InstantSearch>
    </Layout>
  )
}

Artists.propTypes = {
  location: PropTypes.object,
}

export default Artists
