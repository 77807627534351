import React from "react"
import { PageProps } from "gatsby"

// Never import components before the layout
// https://github.com/gatsbyjs/gatsby/issues/9733#issuecomment-490132623
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import BookingsList from "@components/BookingsList"
import { useTranslation } from "@hooks"

import Container from "react-bulma-components/lib/components/container"

const Bookings = (props: PageProps) => {
  const [t] = useTranslation()
  return (
    <Layout>
      <SEO title={t("pages.Bookings.seo.title")} />
      <Container>
        <h1>{t("pages.Bookings.heading")}</h1>
        <BookingsList />
      </Container>
    </Layout>
  )
}

export default Bookings
