import React from "react"
import { useSelector } from "react-redux"

import ActivityItem from "./ActivityItem"

import { selectActivities, selectCurrentUser } from "@selectors"

const ActivitiesList = () => {
  const currentUser = useSelector(selectCurrentUser)
  const activities = useSelector(selectActivities)
  return (
    <div>
      {Object.values(activities)
        .sort((a: any, b: any): number =>
          Math.abs(
            (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any)
          )
        )
        .map((activity: any, i) => (
          <ActivityItem key={i} activity={activity} user={currentUser} />
        ))}
    </div>
  )
}

export default ActivitiesList
