import React from "react"
import PropTypes from "prop-types"
import moment from "moment"

import styles from "./styles.module.scss"

const CreatedAt = ({ createdAt }) => {
  if (!createdAt) return null
  return <p className={styles.CreatedAt}>{moment(createdAt).fromNow()}</p>
}

CreatedAt.propTypes = {
  createdAt: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
}

export default CreatedAt
