import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import { PHOTO_TYPES, PHOTO_FLASH } from "@constants"
import styles from "./styles.module.scss"

const Price = ({ price, type, className }) => {
  if (type !== PHOTO_FLASH) return null
  return (
    <p className={classnames("has-text-white", styles.Price, className)}>
      £{price}+
    </p>
  )
}

Price.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(PHOTO_TYPES),
  className: PropTypes.string,
}

export default Price
