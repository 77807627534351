import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const Guests = ({ alt, style, imgStyle, className }) => {
  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "iynk-guests.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            aspectRatio
            # ...GatsbyImageSharpFluid // With blur-up
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.img.childImageSharp.fluid}
      style={style}
      imgStyle={imgStyle}
      alt={alt}
      className={className}
    />
  )
}

Guests.defaultProps = {
  style: {},
  imgStyle: {},
  alt: "IYNK vs Guests",
}

Guests.propTypes = {
  style: PropTypes.object.isRequired,
  imgStyle: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Guests
