import React from "react"
// import { PageProps } from "gatsby"

import { useTranslation } from "@hooks"
import * as C from "@constants"
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import UserArtist from "@components/UserArtist"
import UserStudio from "@components/UserStudio"

const Account = ({ currentUser }) => {
  const [t] = useTranslation()
  return (
    <Layout>
      <SEO title={t("pages.Account.seo.title")} />
      {currentUser.role === C.USERS_ROLE_STUDIO && (
        <UserStudio user={currentUser} />
      )}
      {currentUser.role === C.USERS_ROLE_ARTIST && (
        <UserArtist user={currentUser} />
      )}
    </Layout>
  )
}

export default Account
