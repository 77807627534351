import React from "react"
import { PageProps } from "gatsby"
import { graphql, Link } from "gatsby"
import classnames from "classnames"
import Img from "gatsby-image"
import { get } from "lodash"

import ArticlesList from "@components/ArticlesList"
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import More from "@components/More"
import { useTranslation } from "@hooks"

import Section from "react-bulma-components/lib/components/section"
import Container from "react-bulma-components/lib/components/container"
import Heading from "react-bulma-components/lib/components/heading"
import Columns from "react-bulma-components/lib/components/columns"
import Card from "react-bulma-components/lib/components/card"

import _styles from "./styles.module.scss"

const Articles = (props: PageProps) => {
  const [t] = useTranslation()
  const heroArticle = get(props, "data.heroArticle.edges[0]")
  const articles = get(props, "data.allContentfulBlogPost.edges")
  const artists = get(props, "data.allFeaturedArtists.edges")
  const studios = get(props, "data.allFeaturedStudios.edges")
  const styles = get(props, "data.allFeatured_Styles.edges")
  return (
    <Layout>
      <SEO
        title={t("pages.Articles.seo.title")}
        description={t("pages.Articles.seo.description")}
      />
      {heroArticle && (
        <Section className={_styles.LatestArticle}>
          <Container>
            <Columns breakpoint="mobile" className={"is-reverse-mobile"}>
              <Columns.Column
                mobile={{
                  size: 12,
                }}
                tablet={{
                  size: 4,
                }}
                desktop={{
                  size: 4,
                }}
                widescreen={{
                  size: 4,
                }}
                fullhd={{
                  size: 4,
                }}
                paddingless
              >
                <Card
                  className={classnames(
                    "is-card-equal-height",
                    _styles.ArticlePreview
                  )}
                >
                  <Card.Content className={_styles.ArticlePreview__content}>
                    <Heading renderAs={"h3"} size={4}>
                      <Link
                        to={`/articles/${heroArticle.node.slug}`}
                        className={"has-text-black"}
                      >
                        {heroArticle.node.title}
                      </Link>
                    </Heading>
                    {/* <small
                      className={classnames(
                        "is-block",
                        "is-uppercase",
                        "has-text-black",
                        _styles.ArticlePreview__date
                      )}
                    >
                      {heroArticle.node.publishDate}
                    </small> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          heroArticle.node.description?.childMarkdownRemark
                            .html,
                      }}
                      className={classnames(
                        "is-block",
                        "has-text-black",
                        _styles.ArticlePreview__body
                      )}
                    />
                  </Card.Content>
                  <Card.Footer className={_styles.ArticlePreview__footer}>
                    <More
                      to={`/articles/${heroArticle.node.slug}`}
                      text={t("components.ArticlePreview.more")}
                      linkClassName={"has-text-black"}
                    />
                  </Card.Footer>
                </Card>
              </Columns.Column>
              <Columns.Column
                mobile={{
                  size: 12,
                }}
                tablet={{
                  size: 8,
                }}
                desktop={{
                  size: 8,
                }}
                widescreen={{
                  size: 8,
                }}
                fullhd={{
                  size: 8,
                }}
                paddingless
              >
                <Card
                  className={classnames(
                    "is-card-equal-height",
                    _styles.ArticleImage
                  )}
                >
                  <Img
                    alt={heroArticle.node.name}
                    fluid={heroArticle.node.heroImage.fluid}
                  />
                </Card>
              </Columns.Column>
            </Columns>
          </Container>
        </Section>
      )}
      <ArticlesList
        renderAs={"h1"}
        title={t("pages.Articles.articles")}
        articles={articles}
      />
      <ArticlesList
        title={t("pages.Articles.featuredArtists")}
        articles={artists}
      />
      <ArticlesList
        title={t("pages.Articles.featuredStyles")}
        articles={styles}
      />
      <ArticlesList
        title={t("pages.Articles.featuredStudios")}
        articles={studios}
      />
    </Layout>
  )
}

export default Articles

export const pageQuery = graphql`
  query ArticlesQuery {
    heroArticle: allContentfulBlogPost(
      filter: { isHero: { eq: true } }
      sort: { fields: [publishDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          isHero
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(
              maxWidth: 800
              maxHeight: 500
              quality: 100
              resizingBehavior: FILL
            ) {
              # ...GatsbyContentfulFluid // With blur-up
              ...GatsbyContentfulFluid_noBase64
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(
              maxWidth: 500
              maxHeight: 300
              quality: 100
              resizingBehavior: FILL
            ) {
              aspectRatio
              # ...GatsbyContentfulFluid // With blur-up
              ...GatsbyContentfulFluid_noBase64
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allFeaturedArtists: allContentfulUser(
      filter: { role: { eq: "artist" }, hasArticle: { eq: true } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          title
          slug
          username
          role
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(
              maxWidth: 500
              maxHeight: 300
              quality: 100
              resizingBehavior: FILL
            ) {
              aspectRatio
              # ...GatsbyContentfulFluid // With blur-up
              ...GatsbyContentfulFluid_noBase64
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allFeaturedStudios: allContentfulUser(
      filter: { role: { eq: "studio" }, hasArticle: { eq: true } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          title
          slug
          username
          role
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(
              maxWidth: 500
              maxHeight: 300
              quality: 100
              resizingBehavior: FILL
            ) {
              aspectRatio
              # ...GatsbyContentfulFluid // With blur-up
              ...GatsbyContentfulFluid_noBase64
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allFeaturedStyles: allContentfulStyle(
      filter: { hasArticle: { eq: true } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          style
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(
              maxWidth: 500
              maxHeight: 300
              quality: 100
              resizingBehavior: FILL
            ) {
              aspectRatio
              # ...GatsbyContentfulFluid // With blur-up
              ...GatsbyContentfulFluid_noBase64
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
