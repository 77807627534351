import React, { useState } from "react"
import { PageProps } from "gatsby"
import { graphql } from "gatsby"
import { get } from "lodash"

import { FontAwesomeIcon } from "@lib/fontAwesome"
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import { useTranslation } from "@hooks"

import Section from "react-bulma-components/lib/components/section"
import Container from "react-bulma-components/lib/components/container"
import Heading from "react-bulma-components/lib/components/heading"
import Message from "react-bulma-components/lib/components/message"

const FAQs = (props: PageProps) => {
  const faqs = get(props, "data.allContentfulFaq.edges")
  const state = {}
  faqs && faqs.forEach(({ node }, i) => (state[i] = true))
  const [hidden, setHidden] = useState(state)
  const toggleHidden = i => setHidden({ ...state, [i]: !hidden[i] })
  const [t] = useTranslation()

  return (
    <Layout>
      <SEO
        title={t("pages.FAQs.seo.title")}
        description={t("pages.FAQs.seo.description")}
      />
      <Section>
        <Container>
          <Heading>{t("pages.FAQs.heading")}</Heading>
          {faqs && faqs.length
            ? faqs.reverse().map(({ node }, i) => (
                <Message key={i}>
                  <Message.Header className={"has-background-white"}>
                    <span className={"has-text-black"}>{node.title}</span>
                    <FontAwesomeIcon
                      color={"black"}
                      icon={hidden[i] ? "chevron-down" : "chevron-up"}
                      onClick={() => toggleHidden(i)}
                      size={"sm"}
                    />
                  </Message.Header>
                  <Message.Body hidden={hidden[i]}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: node.body.childMarkdownRemark.html,
                      }}
                    />
                  </Message.Body>
                </Message>
              ))
            : null}
        </Container>
      </Section>
    </Layout>
  )
}

export default FAQs

export const pageQuery = graphql`
  query FaqQuery {
    allContentfulFaq {
      edges {
        node {
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
