import React from "react"
import PropTypes from "prop-types"
import { InstantSearch, connectInfiniteHits } from "react-instantsearch-dom"

import createInfiniteHitsSessionStorageCache from "@lib/createInfiniteHitsSessionStorageCache"
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import Photo from "@components/Photo"
import Search from "@components/Search"
import NoResults from "@components/NoResults"
import { useAlgolia, useIntersect, useHistory, useTranslation } from "@hooks"

import Container from "react-bulma-components/lib/components/container"
import Section from "react-bulma-components/lib/components/section"
import Loader from "react-bulma-components/lib/components/loader"
import Columns from "react-bulma-components/lib/components/columns"
import Masonry from "react-masonry-css"

const Hits = ({ hits, hasMore, refineNext }) => {
  const [setElement, loading] = useIntersect({ load: refineNext })

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  }

  return (
    <Section>
      <Container>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {hits.map((hit, i) => (
            <Photo key={i} photo={hit} index={i} />
          ))}
        </Masonry>
        <Columns breakpoint="mobile">
          <Columns.Column
            key={"loader"}
            mobile={{
              size: 12,
            }}
            tablet={{
              size: 12,
            }}
            desktop={{
              size: 12,
            }}
            widescreen={{
              size: 12,
            }}
            fullhd={{
              size: 12,
            }}
            className={"has-text-centered"}
          >
            {!loading && hasMore ? (
              <div
                ref={setElement}
                className={"is-vcentered has-text-centered"}
              >
                <Loader className={"is-inline-block"} />
              </div>
            ) : (
              <NoResults />
            )}
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

const CustomHits = connectInfiniteHits(Hits)

const Tattoos = ({ location }) => {
  const [t] = useTranslation()
  const searchClient = useAlgolia()
  const [onSearchStateChange, searchState, createURL] = useHistory(location)

  if (!searchClient) return null
  return (
    <Layout>
      <SEO
        title={t("pages.Tattoos.seo.title")}
        description={t("pages.Tattoos.seo.description")}
      />
      <InstantSearch
        indexName={"photos"}
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <Search showForSale={true} />
        <CustomHits
          cache={createInfiniteHitsSessionStorageCache("ais.tattoos")}
        />
      </InstantSearch>
    </Layout>
  )
}

Tattoos.propTypes = {
  location: PropTypes.object,
}

export default Tattoos
