import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@lib/fontAwesome"
import classnames from "classnames"

import styles from "./styles.module.scss"

const More = ({ to, text, icon, size, color, className, linkClassName }) => {
  if (!to) return null
  return (
    <div
      className={classnames(styles.More, {
        ...(className && typeof className === "object"
          ? className
          : { [className]: true }),
      })}
    >
      <Link to={to} className={linkClassName} title={text}>
        <span className={classnames(styles.More__text, "has-text-link")}>
          {text}
        </span>
        <FontAwesomeIcon
          color={color}
          icon={icon}
          size={size}
          className={classnames(styles.More__icon, "has-text-link", {
            ...(linkClassName && typeof linkClassName === "object"
              ? linkClassName
              : { [linkClassName]: true }),
          })}
        />
      </Link>
    </div>
  )
}

More.defaultProps = {
  icon: "chevron-right",
  size: "lg",
  color: "black",
}

More.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.oneOf([
    "lg",
    "xs",
    "sm",
    "1x",
    "2x",
    "3x",
    "4x",
    "5x",
    "6x",
    "7x",
    "8x",
    "9x",
    "10x",
  ]).isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  linkClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default More
