import React from "react"
import { Router } from "@reach/router"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"

import { selectCurrentUser } from "@selectors"
import * as ROUTES from "@constants/routes"

// Also imported in gatsby-browser?
import "@lib/i18n"

// Pages loading from screens so gatsby doesn't create pages automatically
// Public
import Home from "@pages/home"
import Tattoos from "@pages/tattoos"
import Artists from "@pages/artists"
import Studios from "@pages/studios"
// Don't need these as they are created with templates...
// import Tattoo from "@pages/tattoo"
// import User from "@pages/user"
import Articles from "@pages/articles"
import TheApp from "@pages/app"
import FAQs from "@pages/faqs"
import Terms from "@pages/terms"
import Privacy from "@pages/privacy"
import Stats from "@pages/stats"
import Team from "@pages/join-the-team"
import Sell from "@pages/sell-on-iynk"
import BookATattoo from "@pages/book-a-tattoo"
import Add from "@pages/add-your-studio"
// Private
import Account from "@screens/account"
import Book from "@screens/book"
import Activities from "@screens/activities"
import ChangePassword from "@screens/changepassword"
import Bookings from "@screens/bookings"
import Booking from "@screens/booking"
import NotFound from "./404"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const currentUser = useSelector(selectCurrentUser)

  if (!currentUser && location.pathname !== ROUTES.HOME) {
    // TODO: Could change to different page?
    navigate(ROUTES.HOME)
    return null
  }

  return <Component {...rest} />
}

// Is this necessary?

const App = () => (
  <Router>
    {/* Public */}
    <Home path={ROUTES.HOME} />
    <Tattoos path={ROUTES.TATTOOS} />
    <Artists path={ROUTES.ARTISTS} />
    <Studios path={ROUTES.STUDIOS} />
    <TheApp path={ROUTES.APP} />
    <Articles path={ROUTES.ARTICLES} />
    <FAQs path={ROUTES.FAQS} />
    <Terms path={ROUTES.TERMS} />
    <Privacy path={ROUTES.PRIVACY} />
    <Team path={ROUTES.TEAM} />
    <Sell path={ROUTES.SELL} />
    <BookATattoo path={ROUTES.BOOK_A_TATTOO} />
    <Add path={ROUTES.ADD} />
    {/* Dynamic */}
    {/* <Tattoo path={ROUTES.TATTOO} /> */}
    {/* <User path={ROUTES.USER} /> */}
    {/* Admin */}
    <Stats path={ROUTES.STATS} />
    {/* Private */}
    <PrivateRoute component={Bookings} path={ROUTES.BOOKINGS} />
    <PrivateRoute component={Booking} path={ROUTES.BOOKING} />
    <PrivateRoute component={Activities} path={ROUTES.ACTIVITIES} />
    <PrivateRoute component={ChangePassword} path={ROUTES.CHANGE_PASSWORD} />
    <PrivateRoute component={Account} path={ROUTES.ACCOUNT} />
    <PrivateRoute component={Book} path={ROUTES.BOOK} />
    {/* Not found */}
    <NotFound default component={NotFound} path={ROUTES.NOT_FOUND} />
  </Router>
)

export default App
