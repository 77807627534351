import React from "react"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import ActivitiesList from "@components/ActivitiesList"
import { useTranslation } from "@hooks"

import Container from "react-bulma-components/lib/components/container"

const Activities = () => {
  const [t] = useTranslation()
  return (
    <Layout>
      <SEO title={t("pages.Activities.seo.title")} />
      <Container>
        <h1>{t("pages.Activities.heading")}</h1>
        <ActivitiesList />
      </Container>
    </Layout>
  )
}

export default Activities
