import React from "react"
import { useSelector } from "react-redux"

import BookingItem from "./BookingItem"

import { selectBookings, selectCurrentUser } from "@selectors"

const BookingsList = () => {
  const currentUser = useSelector(selectCurrentUser)
  const bookings = useSelector(selectBookings)
  return (
    <div>
      {Object.values(bookings)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .map((booking, i) => (
          <BookingItem key={i} booking={booking} user={currentUser} />
        ))}
    </div>
  )
}

export default BookingsList
